import React from "react"
import Layout from "@components/layout"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import SEO from "@components/seo"
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
const slugify = require("slugify")

const Blogs = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <SEO
        title="Latest Posts"
        description={data.site.siteMetadata.description}
      />
      <div class="page-headline">
        <div class="container">
          <div class="section-heading text-center">
            <h5 class="font-weight-bold text-uppercase text-orange flair mb-0">Blog</h5>
            <h1>Latest Posts</h1>
          </div>
        </div>
      </div>
      <div class="blog-section component" id="trainingspage">
        <Container>
          <div class="row justify-content-between">
            {/* <div class="col-md-12">
              {data.allSanityPost.edges.map(blog => (
                <div class="blog-item bg-light my-5 p-5" key={blog}>
                  <div class="row">
                    <div class="col-md-5 col-sm-12">
                      <Link to={blog.node.slug.current}>
                        <div
                          class="blog-image h-100"
                          style={{
                            backgroundImage: `url(${
                              blog.node.mainImage !== null
                                ? blog.node.mainImage.asset.fluid.src
                                : "https://source.unsplash.com/user/joshhild/500x500"
                            })`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        />
                      </Link>
                    </div>
                    <div class="col-md-7 col-sm-12">
                      <div class="blog-text">
                        <Link to={`/${blog.node.slug.current}`}>
                          <h4>{blog.node.title}</h4>
                        </Link>
                        <p class="pt-2 text-muted">{blog.node.excerpt}</p>
                        <span class="text-muted small">
                          <i class="fa fa-calendar-o pr-1" />
                          {blog.node.publishedAt}
                        </span>
                        <br />
                        <br />
                        <a
                          className="btn btn-primary shadow arrow"
                          href={blog.node.slug.current}
                        >
                          Read MORE
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            {data.allSanityPost.edges.map(blog => (
              <div key={blog} className="col-md-4 mb-4">
                <div className="card shadow h-100">
                  <div
                    className="card-img"
                    style={{
                      backgroundImage: `url(${
                        blog.node.mainImage !== null
                          ? blog.node.mainImage.asset.fluid.src
                          : "https://placeimg.com/600/400/nature"
                      })`,
                    }}
                  >
                    <div className="card-link">
                      <Link
                        to={
                          blog.node.slug !== null
                            ? `/${blog.node.slug.current}`
                            : ""
                        }
                      >
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/play.svg"
                          width={14}
                          height={14}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    {/* <h6 className="text-uppercase card-subtitle mb-2 font-weight-bold text-orange">
                        {blog.node.area}
                      </h6> */}
                    <h4 className="card-title text-primary">
                      {blog.node.title}
                    </h4>
                    <div className="card-created">
                      <p className="text-muted mb-0">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/calendar.svg"
                          width={20}
                          height={20}
                        />
                        <span className="ml-2">{blog.node.publishedAt}</span>
                      </p>
                    </div>
                  </div>
                  <Link
                    className="hidden-link"
                    to={
                      blog.node.slug !== null
                        ? `/${blog.node.slug.current}`
                        : ""
                    }
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Blogs

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          excerpt
          slug {
            current
          }
          body
          categories {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
  }
`
